import * as React from "react";
import { Row, Col, Card, Divider, Typography, Button, Tooltip } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { Link } from "gatsby";
import HeaderComp from "../components/header";
import "../components/layout.css";
import FooterComp from "../components/footer";
import BlogData from "../data/blog.json";

const Blog = () => {
  return (
    <>
      <HeaderComp />
      <div className="blogBanner">
        <Row className="enterpriseTitleRow">
          <Col
            xs={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <h1 className="enterpriseTitle">
            Blog
            </h1>
          </Col>
        </Row>
      </div>
      <div className="blogMainDiv">
      <Row>
        {BlogData.map(item => (
          <Col
            xs={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
            className={item.blogDate == "May 13th, 2020" ? "blogCol" : ""}
            style={{paddingTop: item.blogDate == "May 7th, 2020" ? 50 : 0}}
          >
            <Link to={item.path}>
              <img
                src={item.img}
                alt="blog"
                // className="w3-hover-opacity w3-hover-red"
                // height={290}
              />
            </Link>
            <div style={{paddingLeft: 25}}>
            <h2 className="blogText" style={{width: item.blogDate == "June 2nd, 2020" ? "90%" : ""}}>
            <Link to={item.path} className="blogLink">
              {item.blogText}
              </Link>
            </h2>
            <p className="blogDate">{item.blogDate}</p>
            <Divider />
            <p className="readMoreBlog">
            <Link to={item.path} className="blogLink">
              {item.readMore}{" "}
              <RightOutlined
                style={{ fontSize: "12px", verticalAlign: "middle" }}
              />
              </Link>
            </p>
            </div>
          </Col>
        ))}
        </Row>
      </div>
      <FooterComp />
    </>
  );
};

export default Blog;
